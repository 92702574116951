import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Dashboard from '../../app_components/dashboard/DashBoard';
import Users from './users';
import Students from './students';
import Staffs from './staffs';
import Divisions from './divisions';
import withAdminLayout from '../../layout/withAdminLayout';
import YearBook from './yearbook';
import TemplateBuilders from './templatebuilder';


const NotFound = lazy(() => import('../../container/pages/404'));
const User = lazy(() => import('../../app_components/user/User'));
const Student = lazy(() => import('../../app_components/student/Student'));
const Demo = lazy(() => import('../../app_components/student/Demo'));
const Division = lazy(() => import('../../app_components/division/Divisions'));
const District = lazy(() => import('../../app_components/district/District'));
const School = lazy(() => import('../../app_components/school/School'));
const Staff = lazy(() => import('../../app_components/Staff/Staff'));
const Export = lazy(() => import('../../app_components/ExportTemplate/ExportTemplete'));
const TemplateInfo = lazy(() => import('../../app_components/ExportTemplate/TemplateInfo'));
const PhotoEvent = lazy(() => import('../../app_components/PhotoEvent/PhotoEvent'));
const PrintHistory = lazy(() => import('../../app_components/PrintHistory/PrintHistory'));
const TemplateBuilder = lazy(() => import('../../app_components/TemplateBuilder/TemplateBuilder'));
const SchoolListManager = lazy(() => import('../../app_components/SchoolListManager/SchoolListManager'));
const SchoolListUploader = lazy(() => import('../../app_components/SchoolListUploader/SchoolListUploader'));
const ExportYearbook = lazy(() => import('../../app_components/exportYearbookPSPA/ExportYearbookPSPA'));
const ImportFromLocal = lazy(() => import('../../app_components/ImportFromLocal/ImportFromLocal'));
const RollOver = lazy(() => import('../../app_components/rollOver/RollOver'));
const YearBookList = lazy(() => import('../../app_components/yearBookSelection/YearBookSelection'));
const MissingInformationUploader = lazy(() =>  import('../../app_components/missingInformationUploader/MissingInformationUploader'));
const CommonConversion = lazy(() => import('../../app_components/CommonConversion/CommonConversion'));
const Admin = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route index path="/*" element={<Dashboard />} />
        <Route index path="/user/:id?" element={<User />} />
        {/* <Route index path="/TableList" element={<TableList />} /> */}
        <Route index path="/student/:id?" element={<Student />} />
        <Route path="/student/:SchoolId?/:PhotoShootId?" element={<Student />} />
        {/* <Route path="students/add-student" element={<AddStudent />} /> */}
        <Route path="users/*" element={<Users />} />
        <Route path="students/*" element={<Students />} />
        <Route path="staffs/*" element={<Staffs />} />
        <Route path="divisions/*" element={<Divisions />} />
        <Route path="templatebuilder/*" element={<TemplateBuilders/>} />
        <Route index path="/demo" element={<Demo />} />
        <Route index path="/division" element={<Division />} />
        <Route index path="/district" element={<District />} />
        <Route path="/schools/:id?" exact element={<School />} />
        <Route path="/staff/:id?" element={<Staff />} />
        <Route path="/staff/:SchoolId?/:PhotoShootId?" element={<Staff />} />
        <Route path="/exporttemplate" element={<Export />} />
        <Route path="/photoevent/:id?" element={<PhotoEvent />} />
        <Route path="/printhistory" element={<PrintHistory />} />
        <Route path="/templatebuilder" element={<TemplateBuilder />} />
        <Route path="/schoollistmanager" element={<SchoolListManager />} />
        <Route path="/schoollistuploader" element={<SchoolListUploader />} />
        <Route path="/exportyearbookpspa" element={<ExportYearbook />} />
        <Route path="/exporttemplate/edit" element={<TemplateInfo />} />
        <Route path="/importfromlocal" element={<ImportFromLocal />} />
        <Route path="/rolloverprocess" element={<RollOver />} />
        <Route path="/conversionlist" element={<CommonConversion/>}/>


        <Route index path='/yearbookSelection' element={<YearBookList />} />
        <Route path="yearbookSelections/*" element={<YearBook />} />
        <Route path="/missingInformationUploader" element={<MissingInformationUploader />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
